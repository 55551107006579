import dynamic from 'next/dynamic'

const RegularProductInformation = dynamic(() => import('./RegularProductInformation'), {
  ssr: true
})

const RegularProducts = () => {
  return <RegularProductInformation flex={0.5} w={{ base: '100%', lg: 'auto' }} />
}

export default RegularProducts
