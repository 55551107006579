'use client'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  MenuList,
  SimpleGrid,
  Spacer,
  VStack
} from '@chakra-ui/react'
import type { IGrandSection } from 'ui'
import { ActivityLoader, EmptyMessage, GrandSection } from 'ui'
import { MdProductionQuantityLimits } from 'react-icons/md'
import { useDispatch } from 'shared-redux'
import { AiFillFilter } from 'react-icons/ai'
import type { ProductsState } from 'shared-redux/state'
import { setSelectedSorting } from 'shared-redux/state'
import type { CartItem, DeliveryDTO, ProductLightDTO, SortingOption } from 'ecosystem'
import { useOnScreen } from 'ui/hooks'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import ProductListItemComponent from '../ProductListItem'
import type { ProductListItemProps } from '../ProductListItem'
import FilterMenus from '../FilterMenus'
import MoreFilters from './MoreFilters'
import FilterSorting from './ProductsFilter/FilterSorting'

export interface IProductList extends IGrandSection {
  items: ProductLightDTO[]
  totalItems: number
  deliveryOptions: DeliveryDTO[] | null
  productsState: ProductsState
  sortingOptions: SortingOption[]
  onAddToCart: (cartItem: CartItem) => void
  onMore: () => void
  onSearchUpdate: () => void
  title?: string
  isLoading?: boolean
  onProductRequest: (item: ProductLightDTO) => void
  infiniteScrollConfig: {
    loadMore: () => void
    shouldLoadMore: boolean
  }
  sliderMaxPrice: number
  sliderDefaultValues: [number, number]
  productsUrlPath: string
  customComponents?: {
    ProductListItem?: FC<ProductListItemProps>
  }
}

export interface ProductListVerticalOverrides {
  sort?: string
  productListVerticalTitle?: string
  viewTotalProducts?: string
  noProductsFound?: string
}

const ProductListVertical: FC<IProductList> = ({
  isLoading,
  title,
  items,
  totalItems,
  deliveryOptions,
  infiniteScrollConfig,
  productsState,
  onMore,
  onAddToCart,
  onSearchUpdate,
  sortingOptions,
  sliderMaxPrice,
  onProductRequest,
  productsUrlPath,
  sliderDefaultValues,
  customComponents,
  ...props
}) => {
  const { sortOrder, sortBy, sortLabel } = productsState
  const dispatch = useDispatch()
  const overrides = useOverridesContext<keyof ProductListVerticalOverrides>()

  // ******** INFINITE SCROLL ********

  const loadMoreIntersectionDiv = useRef<HTMLDivElement | null>(null)
  const isLastElementReached = useOnScreen(loadMoreIntersectionDiv)
  const { shouldLoadMore, loadMore } = infiniteScrollConfig

  useEffect(() => {
    if (isLastElementReached && shouldLoadMore && !isLoading) {
      loadMore()
    }
  }, [isLastElementReached, isLoading, shouldLoadMore, loadMore])

  const handleSorting = useCallback(
    (selection: SortingOption) => {
      dispatch(setSelectedSorting(selection))
    },
    [dispatch]
  )

  const filterName = useMemo(() => {
    if (sortBy && sortOrder) {
      return `${overrideText('Sortera', overrides?.sort)}: ${sortLabel}`
    }
    return overrideText('Sortera', overrides?.sort)
  }, [overrides?.sort, sortBy, sortLabel, sortOrder])

  const { ProductListItem = ProductListItemComponent } = customComponents || {}

  return (
    <>
      <GrandSection
        contentContainerProps={{
          spacing: 4
        }}
        {...{ title }}
        {...props}>
        <VStack
          align={{
            base: 'flex-start',
            lg: 'center'
          }}
          justify="flex-start">
          <Button
            aria-label="show-filters"
            bg="background.mildDark"
            borderColor="background.mildDark"
            borderRadius="md"
            display={{ base: 'block', md: 'none' }}
            minH="35px"
            minW={100}
            onClick={onMore}
            size="sm"
            w={{ base: 'full', sm: 'auto' }}>
            <HStack justify="center" w="full">
              <AiFillFilter />
              <Box>{overrideText('Sortera och filtrera', overrides?.productListVerticalTitle)}</Box>
            </HStack>
          </Button>
        </VStack>

        <Divider />

        <HStack align="flex-start" w="full">
          <MoreFilters
            display={{ base: 'none', md: 'flex' }}
            flex={3}
            pt={1}
            w="auto"
            {...{
              productsState,
              onSearchUpdate,
              sliderMaxPrice,
              sortingOptions,
              sliderDefaultValues
            }}
          />

          <Box flex={9} minH={500} position="relative">
            {items.length ? (
              <VStack w="full">
                <HStack justify="space-between" w="full">
                  <Flex
                    alignItems="center"
                    color="text.mild"
                    fontSize="xs"
                    fontWeight="medium"
                    textAlign={{ base: 'right', md: 'left' }}>
                    {overrideText(`visar produkter: ${totalItems}`, overrides?.viewTotalProducts)}
                  </Flex>
                  <Spacer />
                  <Box minW={200}>
                    <FilterMenus
                      filterName={filterName}
                      list={
                        <MenuList zIndex={9999}>
                          <FilterSorting
                            onChange={handleSorting}
                            {...{
                              selectedSorting: {
                                sortBy,
                                sortOrder,
                                sortLabel
                              },
                              sortingOptions
                            }}
                          />
                        </MenuList>
                      }
                    />
                  </Box>
                </HStack>
                <SimpleGrid
                  columns={{
                    base: 2,
                    md: 4
                  }}
                  gap={2}
                  spacing={{
                    base: 1,
                    md: 6
                  }}
                  width="100%">
                  {items.map((item) => (
                    <ProductListItem
                      key={item.id}
                      onBuy={() => {
                        onAddToCart({ ...item, qty: '1' })
                      }}
                      {...{ item, deliveryOptions, onProductRequest, productsUrlPath }}
                    />
                  ))}
                </SimpleGrid>
                {isLoading ? (
                  <ActivityLoader
                    fontSize="xs"
                    h="full"
                    position="relative"
                    spinnerProps={{ size: 'sm' }}
                    text=""
                    w="full"
                    zIndex={0}
                  />
                ) : null}
              </VStack>
            ) : (
              <Flex align="center" h={500} justify="center">
                <EmptyMessage
                  fontSize="xl"
                  icon={<MdProductionQuantityLimits />}
                  text={overrideText('Inga produkter hittades', overrides?.noProductsFound)}
                />
              </Flex>
            )}
          </Box>
        </HStack>
      </GrandSection>
      <Box h="1px" ref={loadMoreIntersectionDiv} w="full" />
    </>
  )
}

export default ProductListVertical
