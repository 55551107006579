import { useEffect, useRef } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { useUpdateEffect, Box } from '@chakra-ui/react'
import { useRefUpdated } from 'ui/hooks/useRefUpdated'
import { useDebounceValue } from 'ui/hooks'
import { INGRID_CDN_URL } from '../constants'
import { loadScript } from './utils'
import type { IngridPDPWidgetApi, IngridPDPWidgetConfig } from './types'

const URL = `${INGRID_CDN_URL}/product-page-widget/bootstrap.js`

interface IngridPDPWidgetOriginalProps {
  config: IngridPDPWidgetConfig
  onReady?: () => void
  onError?: (error: string) => void
  wrapperProps?: BoxProps
}

// https://developer.ingrid.com/product_page/integration/
export const IngridPDPWidgetOriginal = ({
  config,
  onReady,
  onError,
  wrapperProps
}: IngridPDPWidgetOriginalProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const ingridPDPWidgetApiRef = useRef<IngridPDPWidgetApi>()
  const onReadyRef = useRefUpdated(onReady)
  const onErrorRef = useRefUpdated(onError)

  const [debouncedConfig] = useDebounceValue(config, 300)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const container = ref.current

    loadScript(URL, {
      onLoad: () => {
        // @ts-expect-error -- need
        const ingridPDPWidgetApi: IngridPDPWidgetApi = window?._ingridPDPWidgetApi
        ingridPDPWidgetApiRef.current = ingridPDPWidgetApi

        if (!ingridPDPWidgetApi) {
          return
        }

        container.innerHTML = ''

        ingridPDPWidgetApi.addListener('ready', () => {
          onReadyRef.current?.()
        })

        ingridPDPWidgetApi.addListener('error', (error) => {
          onErrorRef.current?.(error)
        })

        ingridPDPWidgetApi.render(container.id, config)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only once
  }, [])

  useUpdateEffect(() => {
    if (ingridPDPWidgetApiRef.current && ref.current) {
      ref.current.innerHTML = ''
      ingridPDPWidgetApiRef.current?.render(ref.current.id, debouncedConfig)
    }
  }, [debouncedConfig])

  return <Box id="ingrid-product-page-widget" ref={ref} {...wrapperProps} />
}
