'use client'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'
import { Box, Flex, SimpleGrid, Stack, VStack } from '@chakra-ui/react'
import { MdProductionQuantityLimits } from 'react-icons/md'
import type { CartItem, DeliveryDTO, ProductLightDTO } from 'ecosystem'
import type { IGrandSection } from 'ui'
import { ActivityLoader, EmptyMessage, GrandSection } from 'ui'
import { useOnScreen } from 'ui/hooks'
import type { ProductsState } from 'shared-redux/state'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import ProductListItemComponent from '../ProductListItem'
import type { ProductListItemProps } from '../ProductListItem'
import type { IProductsFilterProps } from './ProductsFilter/ProductsFilter'
import ProductsFilter from './ProductsFilter/ProductsFilter'

export interface IProductList extends IGrandSection {
  onProductRequest: (item: ProductLightDTO) => void
  items: ProductLightDTO[]
  totalItems: number
  productsState: ProductsState
  onAddToCart: (cartItem: CartItem) => void
  onMore: () => void
  deliveryOptions: DeliveryDTO[] | null
  title?: string
  isLoading?: boolean
  infiniteScrollConfig: {
    loadMore: () => void
    shouldLoadMore: boolean
  }
  productsUrlPath: string
  customComponents?: {
    ProductListItem?: FC<ProductListItemProps>
  }
  filterProps: Omit<IProductsFilterProps, 'productsState' | 'handleMoreButton'>
}

export interface ProductListOverrides {
  viewTotalProducts?: string
  noProductsFound?: string
}

const ProductList: FC<IProductList> = ({
  isLoading,
  title,
  items,
  totalItems,
  deliveryOptions,
  infiniteScrollConfig,
  onAddToCart,
  onMore,
  productsState,
  onProductRequest,
  productsUrlPath,
  customComponents,
  filterProps: { wrapperProps: filterWrapperProps, ...filterProps },
  ...props
}) => {
  const overrides = useOverridesContext<keyof ProductListOverrides>()
  // ******** INFINITE SCROLL ********

  const loadMoreIntersectionDiv = useRef<HTMLDivElement | null>(null)
  const isLastElementReached = useOnScreen(loadMoreIntersectionDiv)
  const { shouldLoadMore, loadMore } = infiniteScrollConfig

  useEffect(() => {
    if (isLastElementReached && shouldLoadMore && !isLoading) {
      loadMore()
    }
  }, [isLastElementReached, isLoading, shouldLoadMore, loadMore])

  const { ProductListItem = ProductListItemComponent } = customComponents || {}

  return (
    <>
      <GrandSection {...{ title }} {...props}>
        <Stack
          align={{
            base: 'flex-start',
            lg: 'center'
          }}
          direction={{ base: 'column', lg: 'row' }}
          justify="flex-start"
          spacing={[2, 4]}>
          <ProductsFilter
            handleMoreButton={onMore}
            wrapperProps={{ width: 'auto', flexGrow: 1, ...filterWrapperProps }}
            {...{ productsState, ...filterProps }}
          />
          <Box
            color="text.mild"
            fontSize="sm"
            fontWeight={600}
            pb={2}
            textAlign={{ base: 'right', md: 'left' }}>
            {overrideText('visar produkter', overrides?.viewTotalProducts)} {totalItems}
          </Box>
        </Stack>
        <Box minH={500} position="relative" w="full">
          {items.length ? (
            <VStack spacing={4} w="full">
              <SimpleGrid
                columns={{
                  base: 2,
                  md: 4
                }}
                spacing={{
                  base: 1,
                  md: 6
                }}
                width="100%">
                {items.map((item) => (
                  <ProductListItem
                    key={item.id}
                    onBuy={() => {
                      onAddToCart({ ...item, qty: '1' })
                    }}
                    {...{ item, deliveryOptions, onProductRequest, productsUrlPath }}
                  />
                ))}
              </SimpleGrid>
              {isLoading ? (
                <ActivityLoader
                  fontSize="xs"
                  h="full"
                  position="relative"
                  spinnerProps={{ size: 'sm' }}
                  text=""
                  w="full"
                  zIndex={0}
                />
              ) : null}
            </VStack>
          ) : (
            <Flex align="center" h={500} justify="center">
              <EmptyMessage
                fontSize="xl"
                icon={<MdProductionQuantityLimits />}
                text={overrideText('Inga produkter hittades', overrides?.noProductsFound)}
              />
            </Flex>
          )}
        </Box>
      </GrandSection>
      <Box h="1px" ref={loadMoreIntersectionDiv} w="full" />
    </>
  )
}

export default ProductList
