import dynamic from 'next/dynamic'

const ConfigurationProductInformation = dynamic(
  async () => import('./ConfigurationProductInformation'),
  {
    ssr: true
  }
)

const ConfigurableProduct = () => {
  return <ConfigurationProductInformation flex={0.5} w={{ base: '100%', lg: 'auto' }} />
}

export default ConfigurableProduct
