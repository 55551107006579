'use client'
import type { StackProps } from '@chakra-ui/react'
import { VStack } from '@chakra-ui/react'
import type { DeliveryDTO, Option, ProductLightDTO } from 'ecosystem'
import { TagType } from 'ecosystem'
import type { FC } from 'react'
import { useMemo } from 'react'
import { getProductImages } from 'api'
import { GrandHeading, GrandNextLink, GrandText, HoverableImages } from 'ui'
import ProductBadges from './ProductBadges'
import ProductListItemBuy from './ProductListItemBuy'

export type ProductListItemProps = StackProps & {
  onBuy: (item: ProductLightDTO) => void
  item: ProductLightDTO
  deliveryOptions: Option<DeliveryDTO[]>
  onProductRequest: (item: ProductLightDTO) => void
  productsUrlPath: string
}

const ProductListItemComponent: FC<ProductListItemProps> = ({
  onBuy,
  onProductRequest,
  item,
  deliveryOptions,
  productsUrlPath,
  ...props
}) => {
  const { mainImageUrl, secondaryImageUrl } = getProductImages(item.medias)
  const productBadges = useMemo(
    () => item.tags?.filter((tag) => tag.type === TagType.BADGE) || [],
    [item.tags]
  )

  return (
    <VStack
      _hover={{
        transform: {
          base: 'none',
          lg: 'translateY(-5px)'
        }
      }}
      bg="background.surface"
      borderRadius="sm"
      justifyContent="center"
      position="relative"
      py={4}
      spacing={1}
      transition="all 0.1s cubic-bezier(.17,.67,.83,.67)"
      {...props}>
      <GrandNextLink
        aria-label={item.name}
        h="full"
        href={`${productsUrlPath}/${item.slug}`}
        w="full">
        <HoverableImages
          alt={item.name}
          badges={
            <ProductBadges
              badges={productBadges}
              imageProps={{
                sizes: `(min-width: 40em) 90px, 
                  (min-width: 30em) 80px,
                  60px
                `
              }}
              scalableSize={[60, 80, 90]}
            />
          }
          firstImgUrl={mainImageUrl}
          secondImgUrl={secondaryImageUrl || ''}
          title={item.name}
        />
      </GrandNextLink>

      <GrandHeading
        as="h3"
        fontSize={{
          base: 'sm',
          md: 'md'
        }}
        isTruncated
        pt={4}
        textAlign="center"
        title={item.name}
        tooltip={item.name}
        w="80%"
      />
      <GrandText
        fontSize={{
          base: 'xs',
          md: 'sm'
        }}
        py={3}
        textAlign="center"
        w="80%">
        {item.brand?.name}
      </GrandText>

      <ProductListItemBuy
        price={{ price: item.price, originalPrice: item.originalPrice }}
        product={item}
        {...{ deliveryOptions, onBuy, onProductRequest, productsUrlPath }}
      />
    </VStack>
  )
}

export default ProductListItemComponent
